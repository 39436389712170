export const TextField = (props) => {
	const { className, value, name, plaseholder, setValue, label, type } = props;

	return (
		<label>
			{label}
			<input
				type={type ?? undefined}
				placeholder={plaseholder}
				value={value}
				name={name}
				className={className}
				onChange={setValue}
			/>
		</label>
	);
};
