export const AreaField = (props) => {
	const { className, value, name, plaseholder, setValue, label } = props;

	return (
		<label>
			{label}
			<textarea
				placeholder={plaseholder}
				value={value}
				name={name}
				className={className}
				onChange={setValue}
			/>
		</label>
	);
};
