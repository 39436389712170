import { configureStore } from '@reduxjs/toolkit';
import formSlicePsiho from './formSlicePsiho';
import formSliceVill from './formSliceVill';
import formSliceSca from './formSliceSca';

export const store = configureStore({
	reducer: {
		formSlicePsiho,
		formSliceVill,
		formSliceSca,
	},
});
