import { Fragment } from 'react';
import { TextField } from '../../ui/TextField';
import { AreaField } from '../../ui/AreaField';
import { useAppDispatch, useAppSelector } from '../../../state/hookRtk';
import {
	addExtraVideoVill,
	addExtraPresentVill,
	changeExtraVill,
	setValueVill,
	selectorCommon,
	setIsUrgencyVill,
	setDateVill,
	setTimeVill,
} from '../../../state/formSliceVill';

const labelList = {
	loading: 'Описание для загрузки на ГК:',
	description: 'ТЗ, вырезки, комментарии:',
	tags: 'Теги через пробел:',
};

export const VillForm = (props) => {
	const date = useAppSelector((state) => state.formSliceVill.date);
	const time = useAppSelector((state) => state.formSliceVill.time);
	const title = useAppSelector((state) => state.formSliceVill.title);
	const isUrgency = useAppSelector((state) => state.formSliceVill.isUrgency);

	const setUrgency = () => {
		dispatch(setIsUrgencyVill(!isUrgency));
		dispatch(setDateVill(isUrgency));
		dispatch(setTimeVill(!isUrgency));
		props.debounceSaveLs();
	};

	const dispatch = useAppDispatch();
	const common = useAppSelector(selectorCommon);
	const video = useAppSelector((state) => state.formSliceVill.video);
	const getCourse = useAppSelector((state) => state.formSliceVill.getCourse);
	const present = useAppSelector((state) => state.formSliceVill.present);
	const extraPresent = useAppSelector(
		(state) => state.formSliceVill.extraPresent
	);
	const extraVideo = useAppSelector((state) => state.formSliceVill.extraVideo);

	const inputHandler = (event, name, idx) => {
		const { value } = event.target;

		if (name === 'extraVideo' && typeof idx === 'number') {
			dispatch(changeExtraVill({ type: 'video', value, idx }));
		} else if (name === 'extraPresent' && typeof idx === 'number') {
			dispatch(changeExtraVill({ type: 'present', value, idx }));
		} else {
			dispatch(setValueVill({ name, value }));
		}
		props.debounceSaveLs();
	};

	const addFieldHandler = (type) => {
		if (type === 'present' && extraPresent.length < 1) {
			dispatch(addExtraPresentVill());
		}

		if (type === 'video' && extraVideo.length < 10) {
			dispatch(addExtraVideoVill());
		}
	};

	const example = `Пример:
  
Часть 1: 00:03- 25:58 (с "здравствуйте")
Вырезать: 
02:46 - 02:51 (с "..." до "...")
04:40 - 04:43 (с "..." до "...")
  
Наложить слайды:
Слайд 1 - 00:06 (после заставки) - 00:12
Слайд 2 - 00:13 - 00:40`;

	return (
		<>
			<div className="fields-date">
				<div className="fields-date__checkbox">
					Срочный монтаж !!!
					<input type="checkbox" onChange={setUrgency} checked={isUrgency} />
				</div>
				<TextField
					type="time"
					name="time"
					className="field"
					plaseholder={''}
					value={time}
					setValue={(e) => inputHandler(e, 'time')}
				/>
				<div>
					<div className="fields-date__checkbox">
						Обычный монтаж
						<input type="checkbox" onChange={setUrgency} checked={!isUrgency} />
					</div>
					<TextField
						type="date"
						name="date"
						className="field"
						plaseholder={''}
						value={date}
						setValue={(e) => inputHandler(e, 'date')}
					/>
				</div>
			</div>
			Название курса и урока:
			<TextField
				name="title"
				className="field"
				plaseholder="ДИ. История дизайна"
				value={title}
				setValue={(e) => inputHandler(e, 'title')}
			/>
			Ссылка на ГК:
			<TextField
				name="getCourse"
				className="field"
				plaseholder="Геткурс"
				setValue={(e) => inputHandler(e, 'getCourse')}
				value={getCourse}
			/>
			<div className="dinamic-elements">
				Ссылка на видео + код доступа:
				<div className="wrapper">
					<TextField
						name="video"
						className="field"
						plaseholder="Видео"
						setValue={(e) => inputHandler(e, 'video')}
						value={video}
					/>
					<button
						className="btn add-field"
						disabled={extraVideo.length >= 9 || video.length < 1}
						onClick={() => addFieldHandler('video')}
					>
						+
					</button>
				</div>
				{extraVideo.map((v, i) => (
					<TextField
						key={i}
						name="video"
						className="field"
						plaseholder="Видео"
						setValue={(e) => inputHandler(e, 'extraVideo', i)}
						value={v}
					/>
				))}
			</div>
			<div className="dinamic-elements">
				Ссылка на презентацию:
				<div className="wrapper">
					<TextField
						name="present"
						className="field"
						plaseholder="Презентация"
						setValue={(e) => inputHandler(e, 'present')}
						value={present}
					/>
					<button
						className="btn add-field"
						disabled={extraPresent.length >= 9 || present.length < 1}
						onClick={() => addFieldHandler('present')}
					>
						+
					</button>
				</div>
				{extraPresent.map((v, i) => (
					<TextField
						key={i}
						name="present"
						className="field"
						plaseholder="Презентация"
						setValue={(e) => inputHandler(e, 'extraPresent', i)}
						value={v}
					/>
				))}
			</div>
			{Object.keys(common).map((key) => {
				return key === 'loading' || key === 'description' ? (
					<Fragment key={key}>
						{labelList[key]}
						<AreaField
							plaseholder={
								key === 'description' ? example : 'Описание для загрузки'
							}
							value={common[key]}
							setValue={(e) => inputHandler(e, key)}
							name={key}
							className="field area"
						/>
					</Fragment>
				) : (
					<Fragment key={key}>
						{labelList[key]}
						<TextField
							plaseholder={key === 'tags' ? 'Теги' : key}
							value={common[key]}
							setValue={(e) => inputHandler(e, key)}
							name={key}
							className="field input"
						/>
					</Fragment>
				);
			})}
		</>
	);
};
