import { createSelector, createSlice } from '@reduxjs/toolkit';
import { ls } from '../utils/ls';
import { getInitialDate } from '../utils/getInitialDate';
import { getInitialTime } from '../utils/getInitialTime';

const initialState = {
	isUrgency: false,
	title: '',
	time: '',
	date: '',
	video: '',
	extraVideo: [],
	present: '',
	extraPresent: [],
	getCourse: '',
	description: '',
	loading: '',
	tags: '',
};

export const formSlice = createSlice({
	name: 'form',

	initialState,
	reducers: {
		setIsUrgencySca(state, action) {
			state.isUrgency = action.payload;
		},
		setValueSca(state, action) {
			const { name, value } = action.payload;
			// eslint-disable-next-line default-case
			switch (name) {
				case 'date':
					state.date = value;
					break;
				case 'time':
					state.time = value;
					break;
				case 'title':
					state.title = value;
					break;
				case 'video':
					state.video = value;
					break;
				case 'present':
					state.present = value;
					break;
				case 'getCourse':
					state.getCourse = value;
					break;
				case 'description':
					state.description = value;
					break;
				case 'tags':
					state.tags = value;
					break;
				case 'loading':
					state.loading = value;
					break;
			}
		},
		changeExtraSca(state, action) {
			const { type, value, idx } = action.payload;
			if (type === 'video') state.extraVideo[idx] = value;
			if (type === 'present') state.extraPresent[idx] = value;
		},

		addExtraVideoSca(state) {
			state.extraVideo.push('');
		},
		addExtraPresentSca(state) {
			state.extraPresent.push('');
		},
		saveToLocalstorageSca(state) {
			const extraVideo = state.extraVideo.filter((el) => el !== '');
			const extraPresent = state.extraPresent.filter((el) => el !== '');
			ls('sca').set({ ...state, extraPresent, extraVideo });
		},
		getFromLocalstorageSca(state) {
			const stateLs = ls('sca').get();
			if (stateLs) {
				Object.keys(stateLs).forEach((key) => {
					const name = key;
					if (stateLs[name]) {
						state[name] = stateLs[name];
					}
				});
			} else {
				state.date = getInitialDate(5);
			}
		},
		setDateSca(state, action) {
			state.date = action.payload ? getInitialDate(7) : '';
		},
		setTimeSca(state, action) {
			state.time = action.payload ? getInitialTime(7) : '';
		},
		clearStateSca(state) {
			Object.keys(state).forEach((key) => {
				const name = key;
				if (name === 'extraPresent') state.extraPresent = [];
				else if (name === 'extraVideo') state.extraVideo = [];
				else if (name === 'isUrgency') state.isUrgency = false;
				else {
					state[name] = '';
				}
			});
			ls('sca').clear();
		},
	},
});

export const {
	addExtraVideoSca,
	addExtraPresentSca,
	changeExtraSca,
	setValueSca,
	setIsUrgencySca,
	saveToLocalstorageSca,
	getFromLocalstorageSca,
	clearStateSca,
	setDateSca,
	setTimeSca,
} = formSlice.actions;

export default formSlice.reducer;

export const selectorCommon = createSelector(
	(state) => state.formSliceSca,
	(slice) => {
		const { description, tags, loading } = slice;
		return {
			description,
			loading,
			tags,
		};
	}
);
