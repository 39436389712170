import { PsiForm } from './forms/departmentForms/PsiForm';
import { VillForm } from './forms/departmentForms/VillForm';
import { ScaForm } from './forms/departmentForms/ScaForm';
import { saveToLocalstoragePsiho } from '../state/formSlicePsiho';
import { saveToLocalstorageVill } from '../state/formSliceVill';
import { saveToLocalstorageSca } from '../state/formSliceSca';
import useDebounce from '../hooks/useDebounce';
import { useAppDispatch } from '../state/hookRtk';

export const FormContainer = (props) => {
	const dispatch = useAppDispatch();
	const debounceSaveLsPsiho = useDebounce(100, () =>
		dispatch(saveToLocalstoragePsiho())
	);
	const debounceSaveLsVill = useDebounce(100, () =>
		dispatch(saveToLocalstorageVill())
	);
	const debounceSaveLsSca = useDebounce(100, () =>
		dispatch(saveToLocalstorageSca())
	);

	const { typeForm } = props;
	return (
		<>
			{typeForm === 'psiho' && (
				<>
					<PsiForm debounceSaveLs={debounceSaveLsPsiho} typeForm={typeForm} />
				</>
			)}
			{typeForm === 'vill' && (
				<>
					<VillForm debounceSaveLs={debounceSaveLsVill} typeForm={typeForm} />
				</>
			)}
			{typeForm === 'sca' && (
				<>
					<ScaForm debounceSaveLs={debounceSaveLsSca} typeForm={typeForm} />
				</>
			)}
		</>
	);
};
