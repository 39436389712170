export const ls = (departament) => {
	const key = 'TG_WEBAPP_' + departament.toUpperCase();

	return {
		get() {
			return JSON.parse(localStorage.getItem(key));
		},
		set(value) {
			localStorage.setItem(key, JSON.stringify(value));
		},
		clear() {
			localStorage.removeItem(key);
		},
	};
};
